<template>
    <RouterView></RouterView>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { RouterView } from 'vue-router';
import HomeC from './pages/Home.vue';

export default defineComponent({
    components: {
        RouterView
    },
    setup() {
        const isLoading = ref(true);

        return {
            isLoading,
        }
    }
});
</script>
<style>
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}
.sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
}
</style>